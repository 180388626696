// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kariera-jsx": () => import("./../src/pages/kariera.jsx" /* webpackChunkName: "component---src-pages-kariera-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../src/pages/o-nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */),
  "component---src-pages-wspolpraca-jsx": () => import("./../src/pages/wspolpraca.jsx" /* webpackChunkName: "component---src-pages-wspolpraca-jsx" */)
}

